import React, {
	useState,
	useEffect,
	useContext,
	Fragment,
	useRef
} from "react";
import {
	Alert,
	ButtonGroup,
	Divider,
	FormControlLabel,
	Grid,
	SwipeableDrawer,
	Switch,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Button
} from "@mui/material";
import { useEnvContext } from "contexts/EnvProvider";
import { FeaturesFlags } from "helpers";
import { WebStoreContext } from "../../contexts/WebStoreProvider";
import {
	DebugWebStoreContext,
	LOCAL_STORAGE_DATE_JUMPED
} from "../contexts/DebugWebStoreProvider";
import SetLoyaltyPointsDialog from "./SetLoyaltyPointsDialog";
import SetAddLoyaltyPointsDialog from "./SetAddLoyaltyPointsDialog";
import SetDailyLoginSeriesDialog from "./SetDailyLoginSeriesDialog";
import SetServerTimeDialog from "./SetServerTimeDialog";
import SetAuthTokenTtlDialog from "./SetAuthTokenTtlDialog";
import { DateTime } from "luxon";
import { GoogleAnalyticsContext } from "../../contexts/GoogleAnalyticsProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	clearGiftGaloreTracker,
	clearDailyLoginTracker,
	clearPoisBountyUserTracker,
	clearPoisBountyUserTrackers,
	clearUserDipActions,
	clearUserDipRequisitions,
	clearUserStickyDip,
	clearWaterfallAllTracker,
	clearWaterfallCurrentTracker,
	debugState,
	getAllDIPs,
	getCurrentSegments,
	getCurrentUserSegments,
	isDebugEnabled,
	onDIPtrigger,
	onSeenRewardedPopUp,
	selectDipState,
	setShowDebugLabels
} from "redux/slices";
import {
	clearAllTrackers,
	clearPurchaseTrackers
} from "redux/slices/web-store/thunks";
import ClubShopDialog from "./ClubShopDialog";
import ConfirmDialog from "./ConfirmDialog";
import ResultConfirmationDialog from "./ResultConfirmationDialog";
import SegmentationDialog from "./SegmentationDialog";
import SetClubGiftsGalorePurchaseDialog from "./SetClubGiftsGalorePurchaseDialog";
import { CGG_POP_UP_REWARDED } from "pages/ClubShop/ClubEventSection/useInitialActions";
import SetEndlessOfferPurchaseDialog from "./SetEndlessOfferPurchaseDialog";
import { useClearDebugExperimentsMutation, useDeleteFeatureTrackerMutation } from "redux/apis";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import GetLocalTimeZones from "./SetLocalTime";
import ExperimentsDialog from "./ExperimentsDialog";

/*
TODO: aaron: fix this error (only happening for me), for now casting as any

const SwipeableDrawer: React.JSXElementConstructor<SwipeableDrawerProps>
'SwipeableDrawer' cannot be used as a JSX component.
	Its element type 'Component<any, any, any> | ReactElement<any, any> | null' is not a valid JSX element.
		Type 'Component<any, any, any>' is not assignable to type 'Element | ElementClass | null'.
			Type 'Component<any, any, any>' is not assignable to type 'ElementClass'.
				The types returned by 'render()' are incompatible between these types.
					Type 'React.ReactNode' is not assignable to type 'import("/Users/aostrowsky/Code/GsnGames/gsnsocial/tripeaks-web/frontend/node_modules/@types/react-transition-group/node_modules/@types/react/index").ReactNode'.
						Type '{}' is not assignable to type 'ReactNode'.

*/
const MySwipeableDrawer = SwipeableDrawer;

const DebugConsole = () => {
	const dispatch = useAppDispatch();
	const { debug, data } = useAppSelector(selectDipState);
	const { isFeatureFlagEnabled } = useEnvContext();
	const isDIPsEnabled = isFeatureFlagEnabled(FeaturesFlags.DIPS);
	const dips = debug.data?.dips ?? data.dips;
	const timerRef = useRef<number>();
	const { debugEnabled } = useAppSelector(debugState);
	const {
		userTracker,
		syncUserTracker,
		serverNamespace,
		namespaceOverride,
		setNamespaceOverride,
		setDryRunOverride,
		nowTime,
		setNowTime,
		logout
	} = useContext(WebStoreContext);
	const {
		timeJump,
		resetTimeJump,
		clearInboxMessages,
		clearFirstLoginReward,
		clearUser,
		enableDebugAccess,
		handleRefresh,
		authToken,
		setAuthTokenTtl,
		resetClubShopFreeGift,
		simulateMemberClicks
	} = useContext(DebugWebStoreContext);
	const { adsPersonalization, enableAdsPersonalization } = useContext(
		GoogleAnalyticsContext
	);
	const [open, setOpen] = useState(false);
	const [confirmResultDialog, setConfirmResultDialog] = useState(false);
	const [confirmResultTitle, setConfirmResultTitle] = useState("");
	const [alternateNamespace, setAlternateNamespace] = useState("");
	const [deleteFeatureTracker] = useDeleteFeatureTrackerMutation();
	const [clearExperiments] = useClearDebugExperimentsMutation();
	const showDebugLabels = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);

	useEffect(() => {
		if (serverNamespace === "develop") {
			setAlternateNamespace("dev-release");
		} else if (serverNamespace === "dev-release") {
			setAlternateNamespace("develop");
		} else if (serverNamespace === "live") {
			setAlternateNamespace("prod-release");
		} else if (serverNamespace === "prod-release") {
			setAlternateNamespace("live");
		}
	}, [serverNamespace]);

	useEffect(() => {
		if (authToken) {
			dispatch(isDebugEnabled());
			const savedTime = localStorage.getItem(LOCAL_STORAGE_DATE_JUMPED);
			savedTime && setNowTime(savedTime);
		}
	}, [authToken]);

	const handleClose = () => {
		setOpen(false);
	};

	const onClickEnableDebugActions = async () => {
		await enableDebugAccess();
		reloadPage();
	};

	const onClickResetDeltaDays = async () => {
		await resetTimeJump();
		localStorage.removeItem(LOCAL_STORAGE_DATE_JUMPED);
		reloadPage();
	};

	const onClickDeltaChangeTime = async (
		days: number,
		hours?: number,
		minutes?: number
	) => {
		await timeJump(nowTime, days, hours, minutes);
		reloadPage();
	};

	const onClickClearInboxMessages = async () => {
		const success = await clearInboxMessages();
		showSuccessDialog(success, "Clear Inbox Messages");
	};

	const onClickClearFirstLogin = async () => {
		const success = await clearFirstLoginReward();
		showSuccessDialog(success, "Clear First Login");
	};

	const onClickClearUser = async () => {
		const success = await clearUser();
		logout();
		localStorage.clear();
		showSuccessDialog(success, "Clear User");
		reloadPage();
	};
	const onClickShowHideDebugLabels = async () => {
		dispatch(setShowDebugLabels(!showDebugLabels));
	};
	const onClearAllTrackers = async () => {
		dispatch(clearAllTrackers());
		showSuccessDialog(true, "All trackers cleared");
	};

	const onClearPurchaseTrackers = async () => {
		dispatch(clearPurchaseTrackers());
		showSuccessDialog(true, "Purchase trackers cleared");
	};
	const onClickSetAuthToken = async (authTtlValue: number) => {
		const success = await setAuthTokenTtl(authTtlValue);
		showSuccessDialog(success, "Set Auth Token");
	};

	const onClearUserDipActions = (): void => {
		dispatch(clearUserDipActions());
		showSuccessDialog(true, "DIP actions cleared");
	};

	const onClearGiftsGaloreTracker = (): void => {
		dispatch(clearGiftGaloreTracker());
		localStorage.removeItem(CGG_POP_UP_REWARDED);
		showSuccessDialog(true, "Club Gifts Galore tracker cleared");
	};

	const onClearEndlessOffersTracker = async () => {
		//dispatch(clearEndlessOfferTracker());
		await deleteFeatureTracker({ FeatureType: "endless-offer" }).unwrap();
		showSuccessDialog(true, "Endless Offers tracker cleared");
	};

	const onClearGiftsGaloreViewCount = (): void => {
		localStorage.removeItem(CGG_POP_UP_REWARDED);
		showSuccessDialog(true, "Club Gifts Galore view count cleared");
	};

	const onClearGiftsGaloreSeenReward = (): void => {
		dispatch(onSeenRewardedPopUp(false));
		showSuccessDialog(true, "Club Gifts Galore seen reward set to false");
	};

	const onClearUserDipRequisitions = (): void => {
		dispatch(clearUserDipRequisitions());
		showSuccessDialog(true, "DIP requisitions cleared");
	};

	const onClearUserStickyDip = (): void => {
		dispatch(clearUserStickyDip());
		showSuccessDialog(true, "Sticky DIP cleared");
	};

	const onClearAll = (): void => {
		dispatch(clearUserStickyDip());
		dispatch(clearUserDipActions());
		dispatch(clearUserDipRequisitions());
		showSuccessDialog(true, "All cleared");
	};

	const onClearUserPoisBountyTracker = (): void => {
		dispatch(clearPoisBountyUserTracker());
		showSuccessDialog(true, "User tracker cleared");
	};

	const onClearUserPoisBountyTrackers = (): void => {
		dispatch(clearPoisBountyUserTrackers());
		showSuccessDialog(true, "All User trackers cleared");
	};

	const onClearWaterfallTracker = (): void => {
		dispatch(clearWaterfallCurrentTracker());
		showSuccessDialog(true, "All User trackers cleared");
	};

	const onClearDailyLoginStreakTracker = (): void => {
		dispatch(clearDailyLoginTracker());
		showSuccessDialog(true, "Daily Login Streak tracker cleared");
	};

	const onClearWaterfallTrackers = (): void => {
		dispatch(clearWaterfallAllTracker());
		showSuccessDialog(true, "All User trackers cleared");
	};

	const onClickSetSimulateClubShopMemberTaps = async () => {
		const success = await simulateMemberClicks();
		showSuccessDialog(success, "Set Club Shop Member Taps");
	};
	const onResetFreeClubGiftButton = async () => {
		const success = await resetClubShopFreeGift();
		showSuccessDialog(success, "Reset Club Gift");
	};

	const onClearDripOfferTracker = async () => {
		await deleteFeatureTracker({ FeatureType: "drip-offer" }).unwrap();
		showSuccessDialog(true, "Drip Offer tracker cleared");
	};

	const onClearABExperiments = async () => {
		await clearExperiments("").unwrap();
		showSuccessDialog(true, "A/B experiments cleared");
	};

	const showSuccessDialog = async (success: boolean, title: string) => {
		if (success) {
			setConfirmResultTitle(title);
			setConfirmResultDialog(true);
		}
	};

	const toggleDrawer = (open: boolean) => () => {
		setOpen(open);
	};

	const onClickOpenAdminTool = () => {
		let adminUri;
		if (serverNamespace === "develop" || serverNamespace === "dev-release") {
			adminUri = "https://ps.tripeaksapp-dev.com";
		} else {
			adminUri = "https://ps.tripeaksapp.com";
		}
		window.open(
			`${adminUri}/admin/?paramType=uuid&paramValue=${userTracker?.id}#tab=player`
		);
	};

	const onClickClearStorage = () => {
		localStorage.clear();
		sessionStorage.clear();
		reloadPage();
	};

	const reloadPage = () => {
		clearTimeout(timerRef.current);
		timerRef.current = window.setTimeout(() => {
			window.location.reload();
		}, 3000);
	};

	useEffect(() => {
		// TODO: sync onClose - verify all good compared to sync onOpen
		if (!open && authToken?.value) {
			const onClose = async () => {
				await syncUserTracker();
			};
			onClose();
		}
		/* eslint-disable-next-line */
		const handleKeyDown = (event: any) => {
			if (event.key === "D" || event.key === "d" || event.keyCode === 27) {
				setOpen(!open);
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return function cleanup() {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [open]);

	const handleNamespaceToggle = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string | null
	) => {
		if (newAlignment === serverNamespace) {
			setNamespaceOverride(null);
		} else {
			setNamespaceOverride(alternateNamespace);
		}
		handleRefresh();
		reloadPage();
	};

	/* eslint-disable */
	const handleDryRunToggle = (
		event: React.MouseEvent<HTMLElement>,
		value: any
	) => {
		setDryRunOverride(value);
	};
	/* eslint-enable */

	const handleAdsPersonalizationChange = () => {
		enableAdsPersonalization(!adsPersonalization, userTracker);
	};

	useEffect(() => {
		if (!isDIPsEnabled || !userTracker) {
			return;
		}
		dispatch(getCurrentSegments());
		dispatch(getCurrentUserSegments());
	}, [userTracker, isDIPsEnabled]);

	return (
		<MySwipeableDrawer
			anchor={"right"}
			open={open}
			variant="temporary"
			onClose={toggleDrawer(false)}
			onOpen={toggleDrawer(true)}
			disableSwipeToOpen={false}
			hideBackdrop={true}
			sx={{
				"& > :not(style)": { p: 1, width: "35ch", marginTop: "50px" }
			}}
		>
			<br />
			<div>
				<ButtonGroup>
					<Button color="success" variant="contained" onClick={handleRefresh}>
						Refresh
					</Button>
					<Button color="error" variant="contained" onClick={handleClose}>
						X
					</Button>
				</ButtonGroup>
			</div>

			{debugEnabled.success && !debugEnabled.enabled && (
				<>
					<Alert style={{ margin: "1rem 0" }} severity="error">
						User is not enabled for debug in TriPeaks. Click on Open Admin Tool to
						enable or click here.
					</Alert>
					<ButtonGroup>
						<Button onClick={onClickEnableDebugActions}>Enable</Button>
					</ButtonGroup>
				</>
			)}

			<>
				<h1>Payment Mode</h1>
				<br />
				<div>
					<Grid container>
						<Grid item>
							<ToggleButtonGroup
								exclusive
								onChange={handleDryRunToggle}
								aria-label="text alignment"
							>
								<ToggleButton
									value=""
									aria-label="left aligned"
									color="success"
								>
									Default
								</ToggleButton>
								<ToggleButton value="true" aria-label="centered" color="error">
									Test
								</ToggleButton>
								<ToggleButton value="false" aria-label="centered" color="error">
									Real
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
				</div>

				<br />
				<Divider />
			</>

			{serverNamespace && (
				<>
					<h1>Environment</h1>
					<div>
						<TextField
							disabled
							label="Server Backend"
							value={serverNamespace.toUpperCase()}
						/>
					</div>
					<br />
					<div>
						<Grid container>
							<Grid item>LiveOps Namespace</Grid>
							<Grid item>
								<ToggleButtonGroup
									value={
										namespaceOverride ? namespaceOverride : serverNamespace
									}
									exclusive
									onChange={handleNamespaceToggle}
									aria-label="text alignment"
								>
									<ToggleButton
										value={serverNamespace}
										aria-label="left aligned"
										color="success"
									>
										{serverNamespace}
									</ToggleButton>
									<ToggleButton
										value={alternateNamespace ? alternateNamespace : ""}
										aria-label="centered"
										color="error"
									>
										{alternateNamespace}
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
					</div>

					<br />
					<Divider />
				</>
			)}

			{!userTracker && <h1>Likely need to Facebook Login</h1>}
			{!userTracker && (
				<Fragment>
					<br />
					<ConfirmDialog
						buttonText={"Clear Storage"}
						onClick={onClickClearStorage}
					/>
				</Fragment>
			)}
			{userTracker && (
				<>
					<h1>Time</h1>
					<div>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<TextField
									label="Server Now"
									value={
										nowTime
											? DateTime.fromISO(nowTime.toISOString())
												.setZone("America/Los_Angeles")
												.toISO()
											: ""
									}
									disabled
								/>
							</Grid>
							<Grid item>
								<SetServerTimeDialog />
							</Grid>
						</Grid>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<Button onClick={onClickResetDeltaDays}>Reset</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button onClick={() => onClickDeltaChangeTime(0, 0, -10)}>
								-10 Minutes
							</Button>
							<Button onClick={() => onClickDeltaChangeTime(0, 0, +10)}>
								+10 Minutes
							</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button onClick={() => onClickDeltaChangeTime(0, -1)}>
								-1 Hour
							</Button>
							<Button onClick={() => onClickDeltaChangeTime(0, +1)}>
								+1 Hour
							</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button onClick={() => onClickDeltaChangeTime(-1)}>-1 Day</Button>
							<Button onClick={() => onClickDeltaChangeTime(+1)}>+1 Day</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button onClick={() => onClickDeltaChangeTime(-7)}>
								-7 Days
							</Button>
							<Button onClick={() => onClickDeltaChangeTime(+7)}>
								+7 Days
							</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button onClick={() => onClickDeltaChangeTime(-30)}>
								-30 Days
							</Button>
							<Button onClick={() => onClickDeltaChangeTime(+30)}>
								+30 Days
							</Button>
						</ButtonGroup>
					</div>
					<br />
					<Divider />
					<h1>User</h1>
					<div>
						<TextField label="User Id" value={userTracker.id} disabled />
					</div>
					<br />
					<div>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<TextField
									label="Loyalty Points"
									value={userTracker.loyaltyPoints.toLocaleString("en-US")}
									disabled
								/>
							</Grid>
							<Grid item>
								<SetLoyaltyPointsDialog />
							</Grid>
						</Grid>
					</div>
					<br />
					<div>
						<TextField
							label="Total Loyalty Point Spend"
							value={userTracker.totalLoyaltyPointSpend.toLocaleString("en-US")}
							disabled
						/>
					</div>
					{/* <br />
					<div>
						<TextField
							label="Daily Login Streak"
							value={userTracker.dailyLoginStreak}
							disabled
						/>
					</div> */}
					<br />
					<div>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<SetAuthTokenTtlDialog onClick={onClickSetAuthToken} />
							</Grid>
						</Grid>
					</div>
					<br />
					<SegmentationDialog />
					<br />
					<ClubShopDialog
						onSetMemberTaps={onClickSetSimulateClubShopMemberTaps}
						onResetFreeClubGiftButton={onResetFreeClubGiftButton}
					/>
					<br />
					<ConfirmDialog
						buttonText={"Clear Inbox Messages"}
						onClick={onClickClearInboxMessages}
					/>
					<br />
					<ConfirmDialog
						buttonText={"Clear First Login"}
						onClick={onClickClearFirstLogin}
					/>
					<br />
					<ConfirmDialog buttonText={"Clear User"} onClick={onClickClearUser} />
					<br />
					<div>
						<ButtonGroup>
							<Button onClick={onClickOpenAdminTool}>Open Admin Tool</Button>
						</ButtonGroup>
					</div>
					<br />
					<ConfirmDialog
						buttonText={"Clear Storage"}
						onClick={onClickClearStorage}
					/>
					<br />
					<div>
						<ButtonGroup>
							<Button onClick={onClickShowHideDebugLabels}>
								Show / Hide Debug Labels
							</Button>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ConfirmDialog
							buttonText={"CLEAR ALL TRACKERS"}
							onClick={onClearAllTrackers}
						/>
					</div>
					<br />
					<div>
						<ConfirmDialog
							buttonText={"CLEAR PURCHASE TRACKERS"}
							onClick={onClearPurchaseTrackers}
						/>
					</div>
					<br />
					<FormControlLabel
						control={
							<Switch
								size="medium"
								checked={adsPersonalization}
								onChange={handleAdsPersonalizationChange}
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
						label="Ads personalization"
					/>
					<br />
					<ResultConfirmationDialog
						title={confirmResultTitle}
						show={confirmResultDialog}
						setConfirmationResultDialog={setConfirmResultDialog}
					/>
					<br />
					<Divider />
				</>
			)}

			{userTracker &&
				(isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) ||
					isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) && (
					<>
						<h1>Pois Bounty</h1>
						<div>
							<ButtonGroup>
								<ConfirmDialog
									buttonText={"clear current tracker"}
									onClick={onClearUserPoisBountyTracker}
								/>
							</ButtonGroup>
						</div>
						<br />
						<div>
							<ButtonGroup>
								<ConfirmDialog
									buttonText={"clear all trackers"}
									onClick={onClearUserPoisBountyTrackers}
								/>
							</ButtonGroup>
						</div>
						<br />
						<div>
							<Grid container direction="row" spacing={0.5}>
								<Grid item>
									<TextField
										label="Add LP to progress bar"
										value={userTracker.loyaltyPoints.toLocaleString("en-US")}
										disabled
									/>
								</Grid>
								<Grid item>
									<SetAddLoyaltyPointsDialog />
								</Grid>
							</Grid>
						</div>
						<br />

						<Divider />
					</>
				)}

			{userTracker && (
				<>
					<h1>Waterfall</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear current tracker"}
								onClick={onClearWaterfallTracker}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear all trackers"}
								onClick={onClearWaterfallTrackers}
							/>
						</ButtonGroup>
					</div>
					<br />
					<Divider />
				</>
			)}

			{userTracker && (
				<>
					<h1>Club Gifts Galore</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear tracker"}
								onClick={onClearGiftsGaloreTracker}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear view count"}
								onClick={onClearGiftsGaloreViewCount}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear has seen reward"}
								onClick={onClearGiftsGaloreSeenReward}
							/>
						</ButtonGroup>
					</div>
					<div>
						<Grid
							container
							sx={{
								marginTop: "1rem",
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<TextField label="Purchases" value={1} disabled />
							</Grid>
							<Grid item>
								<SetClubGiftsGalorePurchaseDialog />
							</Grid>
						</Grid>
					</div>
					<br />
					<Divider />
				</>
			)}

			{userTracker && (
				<>
					<h1>Endless Offers</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear tracker"}
								onClick={onClearEndlessOffersTracker}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<TextField label="Purchases" value={1} disabled />
							</Grid>
							<Grid item>
								<SetEndlessOfferPurchaseDialog />
							</Grid>
						</Grid>
					</div>
					<br />
					<Divider />
				</>
			)}

			{userTracker && isFeatureFlagEnabled(FeaturesFlags.DAILY_BONUS) && (
				<>
					<h1>Daily Login Streak</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear tracker"}
								onClick={onClearDailyLoginStreakTracker}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<Grid
							container
							sx={{
								display: "flex",
								flexDirection: "row"
							}}
						>
							<Grid item>
								<TextField label="Collect Series" value={0} disabled />
							</Grid>
							<Grid item>
								<SetDailyLoginSeriesDialog />
							</Grid>
						</Grid>
					</div>
					<br />
					<Divider />
				</>
			)}

			{userTracker && isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) && (
				<>
					<h1>Drip Offer</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear segment"}
								onClick={onClearDripOfferTracker}
							/>
						</ButtonGroup>
					</div>
					<div>
						<GetLocalTimeZones />
					</div>
					<br />
					<Divider />
				</>
			)}

			{userTracker && (
				<>
					<h1>A/B Testing</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear experiments"}
								onClick={onClearABExperiments}
							/>
						</ButtonGroup>
					</div>
					<br />
					<ExperimentsDialog />
					<br />
					<Divider />
				</>
			)}

			{userTracker && (
				<>
					<h1>DIPs</h1>
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear user actions"}
								onClick={onClearUserDipActions}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear user requisitions"}
								onClick={onClearUserDipRequisitions}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog
								buttonText={"clear sticky dips"}
								onClick={onClearUserStickyDip}
							/>
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<ConfirmDialog buttonText={"clear all"} onClick={onClearAll} />
						</ButtonGroup>
					</div>
					<br />
					<div>
						<ButtonGroup>
							<Button variant="outlined" onClick={() => dispatch(getAllDIPs())}>
								LOAD ALL DIPS
							</Button>
						</ButtonGroup>
						{dips && (
							<div style={{ marginTop: "1rem" }}>
								<strong>{!debug.data ? "DIPs" : "ALL DIPs"} </strong>
								<ul style={{ padding: "0 1rem" }}>
									{dips.map(({ dip, isUserMatch }, key) => (
										<li key={key} style={{ marginBottom: "4px" }}>
											<div
												style={{
													alignItems: "center",
													display: "grid",
													gridTemplateColumns: "120px 80px",
													gridGap: "10px",
													backgroundColor: isUserMatch
														? "yellow"
														: "transparent"
												}}
											>
												<span
													style={{ wordBreak: "break-all", fontSize: "13px" }}
													title={dip.id}
												>
													{dip.id}: {dip.triggerType}
												</span>
												<Button
													onClick={() =>
														dispatch(onDIPtrigger(dip.triggerType))
													}
													variant="outlined"
												>
													OPEN
												</Button>
											</div>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<br />
					{debug.userSegment ? (
						<div style={{ marginTop: "1rem" }}>
							<strong>Current User Segmentation Data</strong>
							<div
								style={{
									marginTop: "1rem",
									width: "100%",
									fontSize: 11,
									whiteSpace: "pre",
									border: "1px solid black",
									borderRadius: "5px"
								}}
							>
								{debug?.userSegment
									? JSON.stringify(debug?.userSegment, null, 2)
									: ""}
							</div>
						</div>
					) : null}
					<div style={{ marginTop: "1rem", marginBottom: "5rem" }}>
						<strong>Segments</strong>
						<div>
							{
								/* eslint-disable-next-line */
								debug.segments.map((item: any) => (
									<div
										key={item.id}
										style={{
											marginTop: "1rem",
											width: "100%",
											fontSize: 11,
											whiteSpace: "pre",
											border: "1px solid black",
											borderRadius: "5px"
										}}
									>
										{item ? JSON.stringify(item, null, 2) : ""}
									</div>
								))
							}
						</div>
					</div>
					<br />
					<br />
					<Divider />
				</>
			)}
		</MySwipeableDrawer>
	);
};

export default DebugConsole;
