import { Box, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { crownWhiteIconPath as crownIconPath, CrownIcon } from "utils/svgIcons";
import {
	Bonus,
	CrossOutLine,
	CrossOutValue,
	ImageWrap,
	RewardImage,
	StyledCard,
	Title
} from "pages/StoreFront/lib/OfferCard/styled";
import Button from "components/Buttons/ThemeButton";
import classnames from "classnames";
import { useAppDispatch } from "hooks";
import { onDIPtrigger } from "redux/slices";
import { IShopLoyaltyOffer } from "contexts/WebStoreProvider/types";
import { useWebStoreContext, useGoogleAnalyticsContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

const LoyaltyOfferCard = ({
	className,
	data: {
		id: offerId,
		rewardId,
		bannerText,
		description,
		loyaltyPointPrice: price,
		priority,
		sectionName,
		rewardImageUrl,
		endTime,
		campaignGroup,
		crossOutValue
	},
	backgroundImageUrl,
	itemRef
}: {
	uniqueKey?: string;
	className?: string;
	data: IShopLoyaltyOffer;
	backgroundImageUrl: string;
	itemRef?: React.MutableRefObject<HTMLDivElement | null>;
}): JSX.Element => {
	const { authToken, userTracker, setActiveItemToBeClicked } =
		useWebStoreContext();
	const {
		syncUserTracker,
		setCurrentOffer,
		openLoginToContinueDialog,
		currentOffer,
		networkErrorDetected
	} = useWebStoreContext();

	const { reportLoyaltyPurchaseEvent, reportEvent } =
		useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const [isCurrentOffer, setIsCurrentOffer] = useState<boolean>(false);
	const buyButtonRef = useRef(null);
	const dispatch = useAppDispatch();
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);

	const resetCurrentOffer = () => {
		setIsCurrentOffer(false);
		setCurrentOffer(null);
	};

	const onClickConfirmBuy = async () => {
		reportLoyaltyPurchaseEvent(
			"loyalty_purchase_started",
			userTracker?.id as string,
			endTime,
			offerId,
			rewardId,
			priority,
			price,
			campaignGroup,
			sectionName
		);
		setCurrentOffer(buyButtonRef.current);

		let latestUserTracker = userTracker;
		if (networkErrorDetected) {
			const userTrackerResponse = await syncUserTracker();
			if (userTrackerResponse) {
				latestUserTracker = userTrackerResponse.userTracker;
			}
		}

		if (!authToken?.value) {
			setActiveItemToBeClicked({
				type: "loyalty_offer",
				offerId,
				description,
				price,
				rewardImageUrl,
				cbClose: resetCurrentOffer
			});
			openLoginToContinueDialog();
		} else if (
			// intercept insufficient points before making request
			typeof latestUserTracker?.loyaltyPoints !== "undefined" &&
			latestUserTracker.loyaltyPoints < price
		) {
			openDialog(WsDialogs.INSUFFICIENT_LOYALTY_POINT_BALANCE, {
				cbClose: resetCurrentOffer
			});
			reportEvent("dialog_shown", { source: "insufficient_funds_dialog" });
		} else {
			openDialog(WsDialogs.LOYALTY_PURCHASE_CONFIRM, {
				offerId,
				itemDescription: description,
				itemPrice: price,
				itemImageUrl: rewardImageUrl,
				cbClose: () => {
					resetCurrentOffer();
					dispatch(onDIPtrigger("purchaseCancelled-loyalty"));
				}
			});
			reportEvent("dialog_shown", {
				source: "loyalty_purchase_confirm_dialog"
			});
		}
	};

	const StyledImageWrap = styled(ImageWrap)({
		backgroundImage: `url(${backgroundImageUrl})`,
		height: "185px"
	});

	useEffect(() => {
		if (currentOffer === buyButtonRef.current) {
			setIsCurrentOffer(true);
		} else {
			setIsCurrentOffer(false);
		}
	}, [currentOffer]);

	return (
		<StyledCard className={className} ref={itemRef}>
			<StyledImageWrap>
				<RewardImage src={rewardImageUrl} />
			</StyledImageWrap>
			{bannerText && <Bonus>{bannerText}</Bonus>}
			<div className="content">
				<Box>
					<Title>{description}</Title>
					{showOfferId && (
						<Typography
							sx={{
								fontWeight: 700,
								color: "red",
								fontSize: "10px",
								height: "auto",
								textAlign: "center"
							}}
						>
							{offerId}
						</Typography>
					)}
				</Box>
				{/* {BUTTON BOX} */}
				<Box textAlign="center">
					<Box
						sx={{
							position: "relative",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							whiteSpace: "nowrap",
							marginBottom: ".4rem"
						}}
					>
						<CrossOutValue style={{ fontSize: "18px" }}>
							{crossOutValue && (
								<>
									<CrossOutLine />
									<CrownIcon
										style={{
											height: "20px",
											width: "auto",
											marginRight: 2
										}}
									/>
									{Number(crossOutValue).toLocaleString("en-US")}
								</>
							)}
						</CrossOutValue>
					</Box>
					<Button
						ref={buyButtonRef}
						variant="contained"
						color="success"
						size="large"
						onClick={onClickConfirmBuy}
						sx={{
							width: "100%",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							fontSize: "22px"
						}}
						className={classnames({
							isCurrentOffer,
							isCurrentOfferSet: !!currentOffer
						})}
					>
						<>
							<img
								src={crownIconPath}
								alt="crown-icon"
								style={{ marginRight: 3, width: "22px" }}
							/>
							{price.toLocaleString("en-US")}
						</>
					</Button>
				</Box>
			</div>
		</StyledCard>
	);
};

export default LoyaltyOfferCard;
