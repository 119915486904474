/* eslint-disable @typescript-eslint/no-unused-vars */
import XsollaFrame from "./lib/XsollaFrame";
import { useEffect, useRef } from "react";
import { getDebugLogger } from "utils/debugLogger";
import { useAppDispatch, useAppSelector } from "hooks";
import { onOpenPopUp, selectDipState, setTriggerOnCache } from "redux/slices";
import { isJsonString } from "utils/isJsonString";
import { XsollaModalTitle } from "./lib/XsollaDialogTitle";
import { DialogContent, StyledDialog } from "./styled";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";
import { useWebStoreContext } from "contexts";
import {
	featureTrackerAPI,
	offersApi,
	useGetFeatureTrackerQuery
} from "redux/apis";
import { ITierStatus } from "pages/StoreFront/lib/WaterFallSale/lib/WaterFallCard";

const debug = getDebugLogger({
	isEnabled: true,
	color: "#8E44AD"
});

const DialogXsollaPurchase = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onReturnToShopClick: () => void;
	onCloseClick: () => void;
}) => {
	const { userTracker, authToken } = useWebStoreContext();
	const dispatch = useAppDispatch();
	const isComplete = useRef(false);
	const isPurchaseStarted = useRef(false);
	/* eslint-disable */
	const callBackClose = useRef<any>(null);
	const isSuppressOrderConfirm = useRef<any>(false);
	/* eslint-enable */
	const invoiceStatus = useRef<null | "click-btn-pay" | "deliverying" | "done">(
		null
	);
	const { openDialog, dialogState } = useDialogModalRedux();
	const {
		xsollaHost: host,
		xsollaToken: token,
		suppressOrderConfirmation,
		postUserMetadata,
		cbClose,
		onPurchaseStarted,
		onPurchaseSuccess,
		showClose = true,
		title,
		source,
		itemDescription
	} = dialogState;

	const { data } = useAppSelector(selectDipState);

	const { refetch } = useGetFeatureTrackerQuery(
		{
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		},
		{ skip: source !== "waterfall" }
	);

	//const [trigger] = featureTrackerAPI.endpoints.getFeatureTracker.useLazyQuery();

	useEffect(() => {
		if (show) {
			resetIsComplete();
		}
	}, [show]);

	// save cbClose callback to ref so we can call it when we close the dialog on "back to shop" button click
	useEffect(() => {
		if (cbClose) {
			callBackClose.current = cbClose;
		}
	}, [cbClose]);

	useEffect(() => {
		isSuppressOrderConfirm.current = suppressOrderConfirmation === "true";
	}, [suppressOrderConfirmation]);

	const setIsComplete = () => (isComplete.current = true);
	const resetIsComplete = () => (isComplete.current = false);

	const onOrderConfirmation = () => {
		if (
			isComplete.current &&
			userTracker?.metadata?.suppressOrderConfirmation !== "true" &&
			!localStorage.getItem("store.solitairetripeaks.com:suppressConfirmation")
		) {
			openDialog(WsDialogs.SHOP_PURCHASE_SUCCESS, {
				postUserMetadata,
				cbClose
			});
		} else {
			cbClose && cbClose();
			onCloseClick();
		}
	};
	/* eslint-disable-next-line */
	const handleClose = (event?: any, reason?: string) => {
		if (!isComplete.current && reason && reason == "backdropClick") {
			return;
		}
		onOrderConfirmation();
	};

	useEffect(() => {
		/* eslint-disable-next-line */
		const handleMessageEvent = (event: any) => {
			if (!event.origin.toLowerCase().includes("xsolla")) {
				return;
			}

			const eventData =
				event?.data && isJsonString(event.data)
					? JSON.parse(event.data)
					: event.data;

			//console.log("test_055", eventData);

			if (eventData?.command === "open-paystation") {
				if (typeof onPurchaseStarted !== "undefined") {
					onPurchaseStarted({ payStation: eventData?.data?.version });
				}
			}
			/**
			 * once completed set flag so all close events trigger success confirmation dialog
			 */
			if (
				eventData?.action === "complete" ||
				eventData?.data?.action === "open-status-success" // Xsolla v4
			) {
				//console.log("XsollaPostMessage: INVOICE: COMPLETE = true");

				setIsComplete();
				if (typeof onPurchaseSuccess !== "undefined") {
					onPurchaseSuccess();
				}

				if (source === "waterfall") {
					// Start - Optimistic update for waterfall sale
					dispatch(
						featureTrackerAPI.util.updateQueryData(
							"getFeatureTracker",
							{
								FeatureType: "waterfall-sale",
								isAuthenticated: !!authToken?.value
							},
							(draft) => {
								const { sessionTracker } = draft;
								const tier = sessionTracker.tierStatuses.find(
									(t: ITierStatus) => t.completed === false
								);
								const optimistSessionTracker = {
									...sessionTracker,
									tierStatuses: sessionTracker.tierStatuses.map(
										(t: ITierStatus) =>
											t.index === tier?.index ||
												(t.index === tier?.index + 1 && !!t.tier.rewardId)
												? { ...t, completed: true }
												: t
									)
								};
								return {
									...draft,
									sessionTracker: optimistSessionTracker
								};
							}
						)
					);
					// End - Optimistic update for waterfall sale
					refetch();
				}

				if (source === "endlessoffer") {
					dispatch(onOpenPopUp("endless-offer-reward-collected"));
				}

				if (source === "gift_boxes") {
					dispatch(setTriggerOnCache("clubShopPurchase"));
					//dispatch(onDIPtrigger("clubShopPurchase"));
				}
				if (
					source !== "dips" &&
					source !== "endlessoffer" &&
					source !== "drip-offer"
				) {
					if (
						source === "gift_boxes" &&
						data.dips.find((obj) => obj.dip.triggerType === "clubShopPurchase")
					) {
						return;
					}
					dispatch(setTriggerOnCache("purchaseSuccess"));
				}
				dispatch(offersApi.util.invalidateTags(["offersTag"]));
			}
			if (
				eventData?.data?.paymentInfo?.status === "troubled" ||
				eventData?.command === "error" ||
				eventData?.command === "open-status-error"
			) {
				//console.log("test_05_1", "purchaseFailed");
				dispatch(setTriggerOnCache("purchaseFailed"));
			}
			/***/
			debug.logUseEffect({
				iconText: "DialogXsollaPurchase",
				message: [
					"handleMessageEvent:",
					"origin",
					event.origin,
					"eventData",
					eventData
				]
			});
			if (eventData?.data?.action === "create-invoice") {
				console.log("XsollaPostMessage: INVOICE: isPurchaseStarted = true");
				isPurchaseStarted.current = true;
			}
			if (eventData?.command === "click-btn-pay") {
				invoiceStatus.current = "click-btn-pay";
				console.log("XsollaPostMessage: INVOICE: STATUS = 'click-btn-pay';");
			}
			if (eventData?.command === "status") {
				const status = eventData?.data?.paymentInfo?.status;
				switch (status) {
					case "deliverying":
						invoiceStatus.current = "deliverying";
						// console.log('XsollaPostMessage: STATUS = "deliverying"', eventData);
						break;
					case "done":
						invoiceStatus.current = "done";
						// console.log('XsollaPostMessage: STATUS = "done"', eventData);
						break;
					default:
				}
			}

			if (eventData?.data?.action === "show-error-page") {
				console.log(
					"XsollaPostMessage: show-error-page ACTION",
					"eventData:",
					eventData
				);
			}

			/**
			 * "Back to Store" button click event handler (fallback if no autoClose)
			 */
			if (
				eventData?.action === "finish" ||
				eventData?.command === "return"
				// eventData?.command === "change-status" &&
				// eventData?.data?.action === "change-status" &&
				// eventData?.data?.value === "done"
			) {
				console.log(
					"XsollaPostMessage: FINISH",
					`${eventData?.action} === "finish" ||
				${eventData?.command} === "return"`
				);

				callBackClose.current && callBackClose.current();
				handleClose();
			}
		};
		window.addEventListener("message", handleMessageEvent, false);
		return () => {
			window.removeEventListener("message", handleMessageEvent, false);
		};
	}, [source]);

	if (!host || !token) {
		return null;
	}

	return (
		<StyledDialog
			style={{ zIndex: 999999 }}
			onClose={handleClose}
			aria-labelledby="xsolla-dialog-title"
			open={show}
			sx={{
				overflow: "auto"
			}}
		>
			<XsollaModalTitle
				id="customized-dialog-title"
				onClose={() => {
					if (!isComplete.current && source !== "drip-offer") {
						switch (source) {
							case "offer_card":
								dispatch(setTriggerOnCache(`purchaseCancelled-${itemDescription?.split(" ").slice(1).join(" ").replace(" ", "-").toLowerCase()}`));
								break;

							case "gift_boxes":
								dispatch(setTriggerOnCache("purchaseCancelled-club-gift"));
								break;

							default:
								dispatch(setTriggerOnCache("purchaseCancelled-multi-reward"));
								break;
						}
					}
					handleClose();
				}}
				isShowClose={!!showClose}
			>
				{title}
			</XsollaModalTitle>
			<DialogContent
				dividers
				sx={{
					border: "none",
					maxWidth: "850px",
					padding: 0,
					margin: 0,
					borderBottomLeftRadius: { xs: "none", md: "12px" },
					borderBottomRightRadius: { xs: "none", md: "12px" },
					overflow: "hidden",
					position: "relative"
				}}
			>
				<XsollaFrame token={token} host={host} />
			</DialogContent>
		</StyledDialog>
	);
};

export { DialogXsollaPurchase };
